import React from 'react';
import { BTFooter, SAFooter } from '@schibsted-regional/components';

import type { BenefitsPublications } from '../../../types/frapi-types.js';

import { DataController } from '../../../../core/react/DataController.js';
import { Footer as APFooter } from '../../../../aftenposten/views-react/Footer/Footer.js';

export type FooterProps = {
  publication: BenefitsPublications;
  isHermesApp: boolean;
};

const getFooterData = (
  publication: BenefitsPublications,
  isHermesApp: boolean,
) => {
  switch (publication) {
    case 'bt':
      return {
        brandName: 'Bergens Tidende',
        footerComponent: <BTFooter isHermesApp={isHermesApp} />,
      };
    case 'sa':
      return {
        brandName: 'Stavanger Aftenblad',
        footerComponent: <SAFooter isHermesApp={isHermesApp} />,
      };
    case 'ap':
      return {
        brandName: 'Aftenposten',
        footerComponent: <APFooter />,
      };
  }
};

export const Footer: React.FC<FooterProps> = ({ publication, isHermesApp }) => {
  const { brandName, footerComponent } = getFooterData(
    publication,
    isHermesApp,
  );

  return (
    <>
      {footerComponent}
      <DataController brandName={brandName} />
    </>
  );
};
